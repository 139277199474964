import { render, staticRenderFns } from "./claimDom.vue?vue&type=template&id=0400c8e8&scoped=true"
import script from "./claimDom.vue?vue&type=script&lang=js"
export * from "./claimDom.vue?vue&type=script&lang=js"
import style0 from "./claimDom.vue?vue&type=style&index=0&id=0400c8e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0400c8e8",
  null
  
)

export default component.exports