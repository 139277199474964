<template>
  <div class="page">
    <div class="setEmail flex-column flex-center" v-if="!infos.email">
      <span>{{ $t("account.setAccount") }}</span>
      <div class="setup flex align-center hover" @click="$refs.bindEmailDom.emailShow = true">
        <img src="../assets/account/setup.png" style="width: 20px; height: 14px" />
        <span>{{ $t("account.setEmail") }}</span>
      </div>
    </div>
    <!-- 未激活  0-未激活，1 - BGG 激活；2- BRP激活 -->
    <!-- <div class="acrive_box d-flex ai-center jc-between hidden-sm-and-up"
      :class="infos.vip_active_status == 0 ? 'acrive_box' : 'acrive_box_jihuo'">
      未激活
      <img v-if="infos.vip_active_status == 0" src="../assets/account/vip.png" alt="" style="width:74px;height:67px">
      已激活
      <img v-else src="../assets/account/vip2.png" alt="" style="width:74px;height:67px">
      <div class="btn_group px-15 w-100 m-t-5 d-flex ai-center jc-between bold t-center"
        v-if="infos.vip_active_status == 0">
        <div class="btn rp_btn d-flex flex-column jc-center cursor" @click="onBeginAvtive('brp')">
          <p>{{ sets.brp_vip_active_amount }}MDV{{ $t("account.Activate") }}</p>
        </div>
        <div class="btn bgg_btn d-flex flex-column jc-center cursor" @click="onBeginAvtive('bgg')">
          <p>{{ sets.mds_vip_active_amount }}META{{ $t("account.Activate") }}</p>
        </div>
      </div>
      已激活
      <template v-if="infos && infos.vip_active_status != 0">
        <div class="bgblack lh-1 d-flex flex-column bold ai-center jc-center">
          <h3>{{ infos.nickname }} #{{ infos.userid }}</h3>
          <p>{{ infos.email }}</p>
        </div>
        <div class="right_icon bold t-center">{{ $t('account.hasactive') }}</div>
      </template>
    </div> -->
    <div class="flex align-center">
      <h2>{{ $t("account.wallet") }}</h2>
      <div class="address flex align-center padding-row20">
        <span>{{ $t("account.address") }}: {{ addressFilter(infos.address) }}</span>
        <img class="addressDom cursor" src="../assets/account/copy.png"
          style="width: 16px; height: 16px; margin-left: 6px" :data-clipboard-text="infos.address"
          @click="copyFun('.addressDom', infos.address)" />
        <img src="../assets/account/chain.png" style="width: 16px; height: 16px; margin-left: 6px" class="cursor"
          @click="jump()" />
      </div>
    </div>
    <div class="d-flex ai-center flex-wrap">
     <!-- // MDS pc
      <div class="section coin_section m-r-10 px-20 py-30 flex-between margin10-0 hidden-xs-only">
        <div class="flex align-center" style="white-space: nowrap">
          <img src="../assets/account/mdslogo.png" style="width: 35px; height: 35px; margin-right: 10px" />
          <span>{{ Number(infos.coins) }} MDS </span>
        </div>
        <div class="flex">
          // 游戏在线状态：0-不在线；1-在线
          <el-tooltip v-if="infos.is_online == 1" effect="dark"
            :content="$t('account.tip', { type: $t('market.status.depositCoin') })" placement="bottom">
            <div class="btn flex-center align-center cursor">
              <span>{{ $t("market.status.depositCoin") }}</span>
              <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
            </div>
          </el-tooltip>
          <div v-else class="btn flex-center align-center cursor" @click="toDeposit('MDS')">
            <span>{{ $t("market.status.depositCoin") }}</span>
          </div>
          <el-tooltip v-if="infos.is_online == 1" effect="dark"
            :content="$t('account.tip', { type: $t('market.status.withdrawCoin') })" placement="bottom">
            <div class="btn flex-center align-center cursor">
              <span>{{ $t("market.status.withdrawCoin") }}</span>
              <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
            </div>
          </el-tooltip>
          <div v-else class="btn flex-center align-center cursor" @click="toWithdraw('MDS')">
            <span>{{ $t("market.status.withdrawCoin") }}</span>
          </div>
        </div>
      </div> -->
      <!-- //BGG pc -->
      <div class="section coin_section m-r-10 padding30 flex-between margin10-0">
        <div class="flex align-center" style="white-space: nowrap">
          <img src="../assets/login/logo.webp" style="width: 40px; height: auto; margin-right: 10px;border-radius: 10px;" />
          <span>{{ Number(infos.bgg) }} HFH </span>
        </div>
        <div class="flex">
          <!-- // 游戏在线状态：0-不在线；1-在线 -->
          <el-tooltip v-if="infos.is_online == 1" effect="dark"
            :content="$t('account.tip', { type: $t('market.status.depositCoin') })" placement="bottom">
            <div class="btn flex-center align-center cursor">
              <span>{{ $t("market.status.depositCoin") }}</span>
              <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
            </div>
          </el-tooltip>
          <div v-else class="btn flex-center align-center cursor" @click="toDeposit('BGG')">
            <span>{{ $t("market.status.depositCoin") }}</span>
          </div>
          <el-tooltip v-if="infos.is_online == 1" effect="dark"
            :content="$t('account.tip', { type: $t('market.status.withdrawCoin') })" placement="bottom">
            <div class="btn flex-center align-center cursor">
              <span>{{ $t("market.status.withdrawCoin") }}</span>
              <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
            </div>
          </el-tooltip>
          <!-- <div v-else class="btn flex-center align-center cursor" @click="toWithdraw('BGG')">
            <span>{{ $t("market.status.withdrawCoin") }}</span>
          </div> -->
        </div>
      </div>
     <!-- // BNB pc px-20 py-30 -->
      <div class="flex w-100">
        <div class="section coin_section m-r-10 padding30 flex-between margin10-0">
          <div class="flex align-center" style="white-space: nowrap">
            <img src="../assets/account/BNB.png" style="width: 35px; height: 35px; margin-right: 10px" />
            <span>{{ sixNum(balanceBNB) }} BNB </span>
          </div>
          <div class="flex">
            <div class="btn flex-center align-center cursor" @click="toWithdraw('BNB')">
              <span>{{ $t("market.status.withdrawCoin") }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="section coin_section mx-10 padding30 flex-between margin10-0 hidden-xs-only">
          <div class="flex align-center" style="white-space: nowrap">
            <img src="../assets/account/BNB.png" style="width: 35px; height: 35px; margin-right: 10px" />
            <span>{{ sixNum(old_balanceBNB) }} {{ `BNB(${$t("market.status.old")})` }} </span>
          </div>
          <div class="flex">
            <div class="btn flex-center align-center cursor" @click="toWithdraw('old_BNB')">
              <span>{{ $t("market.status.withdrawCoin") }}</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="d-flex ai-center">

      <!-- ENFT pc -->
      <!-- // 游戏在线状态：0-不在线；1-在线 -->
      <!-- <div class="section padding30 flex-between margin10-0 hidden-xs-only">
        <div class="flex align-center">
          <img src="../assets/account/brclogo.png" style="width: 35px; height: 35px; margin-right: 10px" />
          <span>{{ Number(infos.coins2) || 0 }} ENFT </span>
        </div>
        <div class="flex">
          <el-tooltip v-if="infos.is_online == 1" effect="dark"
            :content="$t('account.tip', { type: $t('market.status.withdrawCoin') })" placement="bottom">
            <div class="btn flex-center align-center cursor">
              <span>{{ $t("market.status.withdrawCoin") }}</span>
              <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
            </div>
          </el-tooltip>
          <div v-else class="btn flex-center align-center cursor" @click="toWithdraw('ENFT')">
            <span>{{ $t("market.status.withdrawCoin") }}</span>
          </div>
        </div>
      </div> -->
    </div>
    <!-- MDS -->
    <!-- <div class="section padding30 margin10-0 hidden-sm-and-up">
      <div class="flex-center align-center">
        <img src="../assets/account/mdslogo.png" style="width: 35px; height: 35px; margin-right: 10px" />
        <span>{{ Number(infos.coins) }} MDS</span>
      </div>
      <div class="flex margin20-0">
        // 游戏在线状态：0-不在线；1-在线
        <el-tooltip v-if="infos.is_online == 1" effect="dark"
          :content="$t('account.tip', { type: $t('market.status.depositCoin') })" placement="bottom">
          <div class="btn flex-center align-center cursor notallow">
            <span>{{ $t("market.status.depositCoin") }}</span>
            <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
          </div>
        </el-tooltip>
        <div class="btn flex-center align-center cursor" @click="toDeposit('MDS')" v-else>
          <span>{{ $t("market.status.depositCoin") }}</span>
        </div>
        <el-tooltip v-if="infos.is_online == 1" effect="dark"
          :content="$t('account.tip', { type: $t('market.status.withdrawCoin') })" placement="bottom">
          <div class="btn flex-center align-center cursor notallow">
            <span>{{ $t("market.status.withdrawCoin") }}</span>
            <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
          </div>
        </el-tooltip>
        <div class="btn flex-center align-center cursor" @click="toWithdraw('MDS')" v-else>
          <span>{{ $t("market.status.withdrawCoin") }}</span>
        </div>
      </div>
    </div> -->
    <!-- BNB -->
    <!-- <div class="section padding30 margin10-0 hidden-sm-and-up">
      <div class="flex-center align-center">
        <img src="../assets/account/BNB.png" style="width: 35px; height: 35px; margin-right: 10px" />
        <span>{{ sixNum(balanceBNB) }} BNB</span>
      </div>
      <div class="flex margin20-0">
        <div class="btn flex-center align-center cursor" @click="toWithdraw('BNB')" style="width:100%">
          <span>{{ $t("market.status.withdrawCoin") }}</span>
        </div>
      </div>
    </div> -->
    <!-- BGG -->
    <!-- <div class="section padding30 margin10-0 hidden-sm-and-up">
      <div class="flex-center align-center">
        <img src="../assets/account/bgg.png" style="width: 35px; height: 35px; margin-right: 10px" />
        <span>{{ Number(infos.bgg) }} META</span>
      </div>
      <div class="flex margin20-0">
        // 游戏在线状态：0-不在线；1-在线
        <el-tooltip v-if="infos.is_online == 1" effect="dark"
          :content="$t('account.tip', { type: $t('market.status.depositCoin') })" placement="bottom">
          <div class="btn flex-center align-center cursor notallow">
            <span>{{ $t("market.status.depositCoin") }}</span>
            <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
          </div>
        </el-tooltip>
        <div class="btn flex-center align-center cursor" @click="toDeposit('BGG')" v-else>
          <span>{{ $t("market.status.depositCoin") }}</span>
        </div>
        <el-tooltip v-if="infos.is_online == 1" effect="dark"
          :content="$t('account.tip', { type: $t('market.status.withdrawCoin') })" placement="bottom">
          <div class="btn flex-center align-center cursor notallow">
            <span>{{ $t("market.status.withdrawCoin") }}</span>
            <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
          </div>
        </el-tooltip>
        <div class="btn flex-center align-center cursor" @click="toWithdraw('BGG')" v-else>
          <span>{{ $t("market.status.withdrawCoin") }}</span>
        </div>
      </div>
    </div> -->
    <!-- ENFT -->
    <!-- // 游戏在线状态：0-不在线；1-在线 -->
    <!-- <div class="section padding30 margin10-0 hidden-sm-and-up">
      <div class="flex-center align-center">
        <img src="../assets/account/brclogo.png" style="width: 35px; height: 35px; margin-right: 10px" />
        <span>{{ Number(infos.coins2) || 0 }} ENFT</span>
      </div>
      <div class="flex margin20-0">
        <el-tooltip v-if="infos.is_online == 1" effect="dark"
          :content="$t('account.tip', { type: $t('market.status.withdrawCoin') })" placement="bottom">
          <div class="btn flex-center align-center cursor notallow">
            <span>{{ $t("market.status.withdrawCoin") }}</span>
            <img src="../assets/account/notice.png" style="width: 16px; height: 16px; margin-left: 6px" />
          </div>
        </el-tooltip>
        <div class="btn flex-center align-center cursor" @click="toWithdraw('ENFT')" v-else>
          <span>{{ $t("market.status.withdrawCoin") }}</span>
        </div>
      </div>
    </div> -->

    <div class="section padding20 margin30-0" v-loading="loading1" element-loading-background="rgba(0, 0, 0, 0.5)">
      <div class="flex ai-center" style="gap: 20px;">
        <div class="tabs flex tab-header-left jc-center">
          <!-- <div class="tab m-r-10 cursor" :class="{ active: tabCurrent === 1 }" @click="change(1)">
            {{ $t("market.dragon") }}
          </div> -->
          <div class="tab cursor flex-center align-center" :class="{ active: tabCurrent === 2 }" @click="handleCreateCard">
            <img src="@/assets/account/icon_add.png" style="width:20px;height:20px;" />
            <span class="m-l-5">{{ $t("market.addDragonBtn") }}</span>
          </div>
        </div>
        <!-- <div class="tab-header-right">
          <div class="blue-btn flex-center align-center cursor fs-min" @click="addDragonBox = true">
            <img src="@/assets/account/icon_add.png" style="width:20px;height:20px;" />
            <span class="m-l-5">{{ $t("market.addDragonBtn") }}</span>
          </div>
        </div> -->
      </div>
      <div class="itemBox flex flex-wrap">
        <!-- 元龍 -->
        <template v-if="tabCurrent === 1">
          <!-- 更新：0 不可操作 10游戏资产(可邻取)， 11上链中(不可操作)， 12在钱包(可存入、可拍卖)， 13拍卖中不可操作, 14正在存入游戏中(不可操作), 15在合约(可领取,可拍卖) -->
          <div class="item px-20 py-15 flex-column align-center" v-for="(item) in list" :key="item.id"
            @click="toDetail(item, 'dragon')">
            <!-- <div class="name text-center" v-if="item.status != 13">LV.{{item.level}} {{ item.dragonname }}</div> -->
            <div class="img relative">
              <div class="jiaobiao" v-if="item.tokenid"><span>#{{ item.tokenid }}</span></div>
              <!-- <img :src="require(`@/assets/dragon/dragon${item.artifactid}.png`)" class="dragon" /> -->
              <img v-if="item.artifactid == 1" :src="require(`@/assets/dragon/fireDragon/dragon (${item.images}).png`)"
                class="dragon" />
              <img v-if="item.artifactid == 2" :src="require(`@/assets/dragon/windDragon/dragon (${item.images}).png`)"
                class="dragon" />
              <img v-if="item.artifactid == 3" :src="require(`@/assets/dragon/iceDragon/dragon (${item.images}).png`)"
                class="dragon" />
              <img v-if="item.artifactid == 4" :src="require(`@/assets/dragon/lightDragon/dragon (${item.images}).png`)"
                class="dragon" />
              <img v-if="item.artifactid == 5" :src="require(`@/assets/dragon/darkDragon/dragon (${item.images}).png`)"
                class="dragon" />
              <div class="block bold t-center" style="color:#F3E9CA;">LV.{{ item.level }}{{ item.dragonname }}</div>
            </div>
            <!-- 拍卖中 -->
            <div class="the_block m-t-10 d-flex flex-column ai-center jc-center" v-if="item.status == 13">
              <Counts :item="item" :auctionBlock="auctionBlock" @callBack="callBack" />
              <p class="bold m-t-5 fs-sm">{{ $t("card.timeout") }} </p>
            </div>
            <div class="modify" v-if="item.status == 13" @click.stop="currentItem = item, modifyBox = true">
              {{ $t("card.modify") }}
            </div>
            <div class="box-status-2 d-flex ai-center jc-between w-100" v-else-if="(item.status == 12)">
              <!-- 拍卖 -->
              <el-button class="oprations" @click.stop="toSell(item, 'dragon')">
                {{ $t("account.paimai") }}</el-button>
              <!-- 轉入遊戲 -->
              <!-- <div class="game_btn t-center" style="margin-top: 15px;" @click.stop="nftDeposit(item, 'dragon')">
                {{ $t("market.status.depositTitle") }}</div> -->
              <!-- <el-button class="game_btn" @click.stop="nftDeposit(item,'dragon')">
                {{ $t("market.status.depositTitle") }}</el-button> -->
            </div>
            <!-- 继续拍卖 -->
            <!-- <el-button class="oprations into_btn fs-md" v-else-if="(item.status == 15)"
              @click.stop="toSell(item,'dragon')">
              {{ $t("market.status.auction") }}
            </el-button> -->
            <div class="d-flex ai-center jc-between w-100 box-status-2" v-else-if="item.status == 15">
              <!-- 继续拍卖 -->
              <el-button class="oprations" @click.stop="toSell(item, 'dragon')">
                {{ $t("market.status.auction") }}
              </el-button>
              <!-- 可提取 提入錢包 -->
              <el-button class="game_btn" @click.stop="nftWithdraw(item, 'dragon')">
                 {{ $t("market.status.withdraw") }}
              </el-button>
            </div>
            <!-- 领取 轉入錢包 -->
            <el-button class="oprations into_btn" @click.stop="nftClaim(item, 'dragon')" v-else-if="(item.status == 10)">
              {{ $t("market.status.claimtitle") }}</el-button>
            <!-- 领取 -->
            <!-- <div class="pick_btn t-center m-t-20 cursor" v-loading="(pickLoading && activeid == item.id)"
              element-loading-background="rgba(0, 0, 0, 0.3)" @click.stop="dragonFun('receive')"
              v-else-if="(item.status == 10)">
              {{ $t("account.receive") }}</div> -->
            <!-- 其他状态 -->
            <div class="oprations into_btn t-center default t-color2 notallow m-t-20 fs-md" v-else>
              <p>{{ status2(item.status) }}</p>
            </div>
          </div>
          <div v-if="!loading1 && list.length <= 0" class="w-100 h-100 d-flex ai-center jc-center"
            style="min-height: 300px">
            <img alt="norecord" src="../assets/public/norecord.png" />
          </div>
        </template>
        <!-- 卡牌 -->
        <template v-else>
          <!-- //0不操作，1可卖，2同步游戏数据中，3上链中, 4卖出确认中, 5挂卖中, 6更新游戏数据中，41上链失败, 40同步失败 -->
          <div class="item item2 flex-column align-center px-15 py-10" v-for="(item, index) in list" :key="index"
            @click="toDetail(item, 'card')">
            <div class="relative" style="width: 180px; height: 260px">
              <!-- 背景图 -->
              <img v-if="item.attrs && item.attrs.herostar && item.attrs.grade"
                :src="require('@/assets/card/itemBigEx' + borderStatus(item.attrs.herostar, item.attrs.grade) + '.png')"
                width="155px" height="245px" class="imgbg" />
              <!-- 英雄图片 -->
              <img v-if="item.attrs && item.attrs.heroid" :src="require('@/assets/hero/' + item.attrs.heroid + '.png')"
                width="160px" height="250px" class="cardimg" />
              <!-- 边框 -->
              <img v-if="item.attrs && item.attrs.herostar && item.attrs.grade"
                :src="require('@/assets/card/bg_kuang' + borderStatus(item.attrs.herostar, item.attrs.grade) + '.png')"
                width="180px" height="260px" class="borders" />
              <template v-if="item.tokenid">
                <div class="icon_box"
                  v-if="item.assets_status == 10 || item.assets_status == 12 || item.assets_status == 15">
                  <p class="fs-md">#{{ item.tokenid }}</p>
                </div>
                <div class="icon_box icon_box3" v-else-if="item.assets_status == 13">
                  <p class="fs-md">#{{ item.tokenid }}</p>
                </div>
                <div class="icon_box icon_box2" v-else>
                  <p class="fs-md">#{{ item.tokenid }}</p>
                </div>
              </template>
              <!-- 级别 -->
              <div class="grade_img d-flex flex-column">
                <img v-if="item.attrs && item.attrs.grade"
                  :src="require('@/assets/card/' + gradeStatus(item.attrs.grade) + '.png')" width="50px" />
                <img v-if="item.attrs.is_sp == 1" :src="require('@/assets/card/sp.png')" width="50px" class="m-t-10" />
              </div>
              <!-- For sale -->
              <div class="forsale d-flex flex-column ai-center jc-center" v-if="item.assets_status == 5">
                <p class="fs-md">{{ $t("account.forsale") }}</p>
                <p class="fs-sm m-t-25">
                  {{ $t("account.salePrice") }}:
                  {{ Number(item.order_price) }}BNB
                </p>
              </div>
              <!-- 名字和星级 -->
              <div class="price t-color4 bold fs-md t-center">
                <span v-if="item.attrs">Lv.{{ item.attrs.herolevel }}</span>
                <span v-if="item.hero_name">{{ item.hero_name }}</span>
                <div class="star d-flex ai-center jc-center m-t-5" v-if="item.attrs">
                  <div class="d-flex ai-center" v-if="item.attrs.herostar <= 5">
                    <img src="@/assets/card/star1big.png" width="16px" v-for="(item, i) in item.attrs.herostar"
                      :key="i" />
                  </div>
                  <div class="d-flex ai-center" v-if="item.attrs.herostar > 5 && item.attrs.herostar <= 9">
                    <img src="@/assets/card/star2big.png" width="16px" v-for="(item, i) in item.attrs.herostar - 5"
                      :key="i" />
                  </div>
                  <div class="d-flex ai-center" v-if="item.attrs.herostar > 9 && item.attrs.herostar <= 11">
                    <img src="@/assets/card/star3big.png" width="16px" v-for="(item, i) in item.attrs.herostar - 9"
                      :key="i" />
                  </div>
                </div>
              </div>
              <!-- 种族 -->
              <img v-if="item.attrs && item.attrs.herocamp"
                :src="require('@/assets/card/bigcamp' + item.attrs.herocamp + '.png')" width="30px" class="camp_img" />
              <!-- 职业 -->
              <img v-if="item.attrs && item.attrs.profession_type"
                :src="require('@/assets/card/type' + item.attrs.profession_type + '.png')" width="30px"
                class="pros_img" />
              <!-- 倒计时 -->
              <!-- <div class="px-20 fs-md d-flex flex-column ai-center jc-center timeout" v-if="item.assets_status == 13">
                <Counts :item="item" :auctionBlock="auctionBlock" @callBack="callBack" />
                <p class="bold m-t-10 t-center">{{ $t("card.timeout") }} </p>
              </div> -->
            </div>
            <div class="box-status-2 flex-center" v-if="item.assets_status == 12">
              <!-- 拍卖 -->
              <div class="oprations text-center cursor m-t-15 fs-md" @click.stop="toSell(item, 'card')">
                {{ $t("market.status.auction") }}
              </div>
              <!-- 存入 轉入遊戲 -->
              <!-- <div class="oprations text-center hasactive2 m-l-10 t-color3 m-t-15 fs-md"
                @click.stop="nftDeposit(item, 'card')">
                {{ $t("market.status.deposit") }}
              </div> -->
            </div>
            <div class="box-status-2 flex-center" v-else-if="item.assets_status == 15">
              <!-- 继续拍卖 -->
              <div class="oprations text-center cursor m-t-15 fs-md" @click.stop="toSell(item, 'card')">
                {{ $t("market.status.auction") }}
              </div>
              <!-- 可提取 提入錢包 -->
              <div class="oprations text-center hasactive2 t-color3 m-t-15 m-l-10 fs-md"
                @click.stop="nftWithdraw(item, 'card')">
                 {{ $t("market.status.withdraw") }}
              </div>
            </div>
            <!-- 拍卖中 -->
            <div class="oprations t-center default t-color2 notallow m-t-15 fs-md w-100"
              v-else-if="item.assets_status == 13">
               {{ $t("market.status.auctioning") }}
            </div>
            <!-- 可领取 轉入錢包 -->
            <div class="oprations text-center cursor m-t-15 fs-md w-100" v-else-if="item.assets_status == 10"
              @click.stop="nftClaim(item, 'card')">
               {{ $t("market.status.claim") }}
            </div>
            <!-- 其他状态 -->
            <div class="oprations t-center default t-color2 notallow m-t-15 fs-md w-100" v-else>
              <p>{{ status(item.assets_status) }}</p>
            </div>
            <!-- 倒计时 -->
            <div class="d-flex ai-center m-t-10 m-b-10" v-if="item.assets_status == 13">
              <p class="bold t-center fs-md m-r-5">{{ $t("card.timeout") }} </p>
              <Counts :item="item" :auctionBlock="auctionBlock" @callBack="callBack" />
            </div>
            <div class="modify" v-if="item.assets_status == 13" @click.stop="currentItem = item, modifyBox = true">
              {{ $t("card.modify") }}
            </div>
          </div>
          <div v-if="!loading1 && list.length <= 0" class="w-100 h-100 d-flex ai-center jc-center"
            style="min-height: 300px">
            <img alt="norecord" src="../assets/public/norecord.png" />
          </div>
        </template>
      </div>
      <Pagination :meta="meta" @pageFun="pageFun"></Pagination>
    </div>

    <!-- 活动 -->
    <div class="section margin30-0 padding20">
      <div class="flex align-center">
        <h2>{{ $t("activity.title") }}</h2>
        <span class="activities" @click="navigate()">{{ $t("activity.view") }} ></span>
      </div>
      <div style="min-height: 300px" v-loading="loading2" element-loading-background="rgba(0, 0, 0, 0.5)">
        <el-timeline v-if="activityList.length" class="timeline-account">
          <el-timeline-item v-for="(item, i) in activityList" :key="i" :timestamp="item.created_at" placement="top">
            <div class="box flex align-center">
              <img v-if="item.type == 1 || item.type == 2 || item.type == 7 || item.type == 8 || item.type == 60"
                src="../assets/account/mdslogo.png" style="width: 35px; height: 35px" />
              <img v-else-if="item.type == 14 || item.type == 15 || item.type == 16 || item.type == 17"
                src="../assets/account/brclogo.png" style="width: 35px; height: 35px" />
              <img v-else-if="item.type == 50 ||
                item.type == 51 ||
                item.type == 52 ||
                item.type == 53 ||
                item.type == 54
                " src="../assets/login/logo.webp" style="width: 35px; height: 35px;border-radius: 10px;" />
              <img v-else-if="item.type == 55
                " src="../assets/account/mdslogo.png" style="width: 35px; height: 35px" />
              <img src="../assets/card/tianshi.png" v-else-if="item.type == 3" width="35px" />
              <img src="../assets/card/shouzu.png" v-else-if="item.type == 4" width="35px" />
              <img src="../assets/summon/sum_icon.png" width="35px" v-else />
              <div class="padding-row10">
                <p v-html="$t('activity.tip.' + item.type, { ...item })"></p>
                <a :href="process + '/tx/' + item.txhash" target="_blank" rel="noopener noreferrer">{{
                  item.txhash
                }}</a>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
        <div v-else-if="!loading2" class="d-flex ai-center jc-center" style="min-height: 300px">
          <img alt="norecord" src="../assets/public/norecord.png" />
        </div>
      </div>
    </div>

    <!-- Add Dragon -->
    <el-dialog :visible.sync="addDragonBox" :show-close="true" :center="true" :modal="false"
      :title="$t('market.addDragonBtn')">
      <el-form>
        <el-form-item>
          <div class="label">{{ $t("market.addDragonBox.id") }}</div>
          <div class="inpDiv">
            
              <el-select v-model="addDragonId" placeholder="Select" style="width: 100%;" fit-input-width popper-class="myCardSelector">
                <el-option
                  v-for="item in levelList"
                  :key="item.key"
                  :label="item.key"
                  :value="item.value"
                />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="label">{{ $t("market.addDragonBox.addQuantity") }}</div>
          <div class="inpDiv">
            <el-input v-model="cardQuantity" autocomplete="off" type="text" oninput="value=value.replace(/[^\d]/g,'')"
              :placeholder="$t('market.addDragonBox.idPlaceholder')"></el-input>
          </div>
        </el-form-item>
        <div class="flex">
          <div class="flex-1">
            <div style="font-size: 12px;color: #fff;margin-bottom: 12px;">{{ $t("market.addDragonBox.totalQuantity") }}: {{ totalQuantityCanCreated }}</div>
            <div style="font-size: 12px;color: #fff;">{{ $t("market.addDragonBox.remainQuantity") }}: {{ remainQuantityCanCreated }}</div>
          </div>
          <div class="flex-1 flex flex-column ai-end">
            <div style="font-size: 12px;color: #fff;margin-bottom: 12px;">{{ $t("market.addDragonBox.costUsdt") }}: {{ cardAmount }}</div>
            <div style="font-size: 12px;color: #fff;">{{ $t("market.addDragonBox.costHfh") }}: {{ hfhQuantity }}</div>
          </div>
        </div>
        <div class="flex align-center jc-between" style="column-gap:10px;">
          <div class="flex-1">
            <el-button
              size="large"
              style="width: 100%;height: 40px;border: 1px solid #37b497 !important; background: transparent !important; color: #37b497 !important;"
              @click="addDragonBox = false">
              {{ $t("market.addDragonBox.btnCancel") }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button size="large" style="width: 100%;height: 40px;" type="primary" @click="addDragonFun()" :loading="isLoading"
              :disabled="addDragonId == ''">
              {{ $t("market.addDragonBox.btnConfirm") }}
            </el-button>
          </div>
        </div>

        <!-- <div class="tip m-t-20" style="font-size:14px;">
          {{ $t("market.addDragonBox.notes") }}<br />
        </div> -->
      </el-form>
    </el-dialog>

    <!-- Deposit存入弹框 -->
    <el-dialog :visible.sync="depositBox" :show-close="true" :center="true" :modal="false"
      :title="$t('market.status.depositCoin')">
      <el-form>
        <!-- <el-form-item>
          <div class="label">{{ $t("menu.account") }}</div>
          <div class="inpDiv">
            <el-input v-model="account" autocomplete="off" readonly></el-input>
          </div>
        </el-form-item> -->

        <el-form-item>
          <div class="flex-between align-center">
            <div class="label">{{ $t("trans.number") }}</div>
            <div class="val">{{ $t("trans.able") }}: {{ balance }}</div>
          </div>
          <div class="inpDiv">
            <el-input v-model="depositForm.number" autocomplete="off"
              @keyup="depositForm.number = (depositForm.number.match(/\d+(\.\d{0,5})?/) || [''])[0]"
              @keyup.enter="depositFun"></el-input>
            <div class="text cursor" @click="depositForm.number = balance">
              {{ $t("trans.total") }}
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100%" type="primary" @click="depositFun()" :loading="isLoading" :disabled="!depositForm.number || !parseFloat(balance)">
            {{ $t("messageBox.confirm") }}</el-button>
        </el-form-item>

        <!-- <div class="tip">
          {{ $t("messageBox.warntip") }}:<br />
          {{ $t("messageBox.depositTip1") }}<br />
          {{ $t("messageBox.depositTip2") }}
        </div> -->
      </el-form>
    </el-dialog>

    <!-- withdraw提币弹框 -->
    <el-dialog :visible.sync="withdrawBox" :show-close="true" :center="true" :modal="false"
      :title="$t('market.status.withdrawCoin') + (withdrawType == 'old_BNB' ?`(${$t('market.status.old')})` :'')">
      <el-form>
        <el-form-item>
          <div class="label">{{ $t("trans.address") }}</div>
          <div class="inpDiv" style="padding-left: 0!important;display: flex;align-items: center;">
            <el-input v-if="withdrawType == 'BNB' || withdrawType == 'old_BNB'" v-model="infos.address" autocomplete="off" @keyup.enter="withdrawFun"
              class="address_input" readonly disabled></el-input>
            <el-input v-else v-model="infos.withdraw_address" autocomplete="off" @keyup.enter="withdrawFun"
              class="address_input" readonly disabled></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="label clr">
            {{ $t("trans.number") }}
            <span v-if="withdrawType == 'MDS'" class="frt">{{ Number(infos.coins) }}</span>
            <!-- <span v-if="withdrawType == 'ENFT'" class="frt">{{ Number(infos.coins2) || 0 }}</span> -->
            <span v-if="withdrawType == 'BGG'" class="frt">{{ Number(infos.bgg) || 0 }}</span>
            <span v-if="withdrawType == 'BNB'" class="frt">{{ sixNum(balanceBNB) }}</span>
            <span v-if="withdrawType == 'old_BNB'" class="frt">{{ sixNum(old_balanceBNB) }}</span>
          </div>
          <div class="inpDiv" style="padding-left: 0!important;display: flex;align-items: center;">
            <el-input v-model="withdrawForm.number" autocomplete="off"
              :placeholder="$t('trans.min_quantity', { name: (withdrawType == 'BGG' ? withdraw_min_number_bgg : withdraw_min_number) + (withdrawType == 'old_BNB' ?'BNB' :withdrawType == 'BGG' ?'META' :withdrawType) })"
              @keyup="withdrawForm.number = (withdrawForm.number.match(/\d+(\.\d{0,5})?/) || [''])[0]"
              @keyup.enter="withdrawFun"></el-input>
            <div class="text cursor"
              @click="getAll">
              {{ $t("trans.total") }}
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="withdrawType != 'BNB' && withdrawType != 'old_BNB'">
          <div class="flex-between align-center">
            <div class="label">{{ $t("trans.arrival") }}</div>
            <div class="val">{{ $t("trans.fee") }} : {{ feeLable }}</div>
          </div>

          <div class="inpDiv">
            <el-input v-model="total" autocomplete="off" readonly>
              <template #suffix>{{ withdrawType == 'old_BNB' ?'BNB' :withdrawType == 'BGG' ?'META' :withdrawType }}</template>
            </el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <div class="label d-flex ai-center jc-between">
            {{ $t("fund.fund") }}
            <span v-if="infos.has_paypass != 1" class="hover t-color8" @click="otherFun()">{{ $t("menu.setting")
            }}</span>
          </div>
          <el-input v-model="withdrawForm.pass" autocomplete="off" :placeholder="$t('fund.fund')" show-password>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="width: 100%" type="primary" @click="withdrawFun()" :loading="isLoading" :disabled="isDisable">
            {{ $t("messageBox.confirm") }}</el-button>
        </el-form-item>
        <p v-if="withdrawType == 'MDS'" class="t-color2">
          {{ $t("messageBox.tip") }}：{{ $t("messageBox.tibi") }}</p>
      </el-form>
    </el-dialog>
    <!-- 修改价格弹窗 -->
    <el-dialog width="378px" :visible.sync="modifyBox" :show-close="true" :center="true" :modal="false"
      :title="$t('card.modify')">
      <el-form>
        <el-form-item>
          <div class="label">{{ $t("card.current_price") }}</div>
          <div class="inpDiv">
            {{ currentItem.bid_price | cutZero }}
          </div>
        </el-form-item>
        <el-form-item>
          <div class="label">{{ $t("card.modify") }}</div>
          <el-input v-model="modify_price" autocomplete="off" :placeholder="$t('card.new_price')">
          </el-input>
        </el-form-item>
        <div class="d-flex ai-center jc-around my-20">
          <el-button class="cancel_btn" @click="modifyBox = false">
            {{ $t("messageBox.cancel") }}</el-button>
          <el-button class="confirm_btn" :loading="modifyLoading" @click="toModify">
            {{ $t("messageBox.confirm") }}</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 绑定邮箱 -->
    <BindEmail ref="bindEmailDom" @callBack="callBack" />
    <!-- 图片验证码 -->
    <Google ref="googleDom" @callBack="callBack" />
    <!-- 输入验证码 -->
    <BindCode ref="bindCodeDom" @callBack="callBack" :email="emailForm.email" />
    <!-- 出售卡片 -->
    <sellDom ref="sellDom" @callBack="callBack" :sellObj="sellObj" />
    <depositDom ref="depositDom" @callBack="callBack" :sellObj="sellObj" />
    <claimDom ref="claimDom" @callBack="callBack" :sellObj="sellObj" />
    <withdrawDom ref="withdrawDom" @callBack="callBack" :sellObj="sellObj" />
    <!-- 输入资金密码 -->
    <el-dialog :visible.sync="setFundBox" :show-close="true" :center="true" :modal="false" :title="$t('fund.fund')">
      <el-input autocomplete="off" :placeholder="$t('fund.p_pass')" v-model="paypass" type="password">
        <!-- 是否有支付密码0-无，1-有 -->
        <template #append v-if="infos.has_paypass == 0">
          <span @click="$router.push('/setting'); setFundBox = false" style="color:#37B497" class="fs-sm hover">{{
            $t("fund.savefund")
          }}</span>
        </template>
      </el-input>
      <el-button style="width: 100%" type="primary" @click="activeShow()" :loading="isfundLoading">
        {{ $t("messageBox.confirm") }}</el-button>
    </el-dialog>
    <el-dialog :visible.sync="avtiveVisible" center :title="$t('account.Activateaccount')" :modal="false">
      <p class="t-center lh-3 t-color2"
        v-html="$t('account.activetip_p', { type: clickType == 'brp' ? sets.brp_vip_active_amount + 'MDV' : sets.mds_vip_active_amount + 'META' })">
      </p>
      <div class="d-flex ai-center jc-around my-20">
        <el-button class="btnpopup can_btn" @click="avtiveVisible = false">
          {{ $t("messageBox.cancel") }}</el-button>
        <el-button class="btnpopup con_btn" @click="onActive">
          {{ $t("messageBox.confirm") }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="bggVisible" center :title="$t('messageBox.mdvTip')" :modal="false">
      <p class="t-color2 t-center">{{ $t("messageBox.nobalance") }}</p>
      <div class="d-flex ai-center jc-around my-20">
        <el-button class="btnpopup can_btn" @click="bggVisible = false">
          {{ $t("messageBox.cancel") }}</el-button>
        <el-button class="btnpopup con_btn" :loading="bggDepositLoading" @click="desBRP">
          {{ $t("messageBox.mdvCofirm") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import BindEmail from "@/components/BindEmail.vue";
import Google from "@/components/Google.vue";
import BindCode from "@/components/BindCode.vue";
import sellDom from "@/components/sellDom.vue";
import depositDom from "@/components/depositDom.vue";
import claimDom from "@/components/claimDom.vue";
import withdrawDom from "@/components/withdrawDom.vue";
import { mapState } from "vuex";
import Clipboard from "clipboard";
import { cancelCards, cancelCardsCallback, getDragons, getcards, business, activebggDragon, getLevelList, createCard, createCard_callback } from "@/api/common/index.api";
import textdata from "../common/dataJaon/text_data.json";
import partner_data1 from "../common/dataJaon/partner_data1.json";
import BigNumber from "bignumber.js";
import abiArray20 from "@/common/abi/abiArray20.json";
import abiArraybgg from "@/common/abi/abibgg.json";
import Counts from "@/components/Counts.vue";
import abiModifyPrice from "@/common/abi/modifyPrice.json";
export default {
  data() {
    return {
      process: process.env.VUE_APP_BSC,
      tabCurrent: "",
      meta: {
        current: 1,
        last: 1,
        total: 0,
      },
      withdrawBox: false,
      modify_price: '',
      modifyBox: false,
      currentItem: {},
      modifyLoading: false,
      depositBox: false,
      addDragonBox: false,
      addDragonId: '',
      cardQuantity: '',
      cardAmount: 0,
      totalQuantityCanCreated: 0,
      remainQuantityCanCreated: 0,
      isLoading: false,
      isDisable: true,
      emailForm: {},
      loading: false,
      disabled: true,
      account: "",
      depositForm: {
        number: "",
      },
      balanceBNB: 0,
      old_balanceBNB: 0,
      balance: "",
      min: "",

      withdrawForm: {
        number: "",
        address: "",
        pass: "",
      },
      withdraw_min_number: 0,
      withdraw_min_number_bgg: 0,
      withdraw_fee: 0,
      withdraw_balance: 0,
      loading1: false,
      list: [],
      page: 1,
      isActive: false,
      pickLoading: false,
      activeid: "",
      activeid2: "",
      activeid3: "",
      sellObj: {},

      loading2: false,
      activityList: [],

      depositType: "",
      withdrawType: "",
      setFundBox: false,
      funtype: '0',
      isfundLoading: false,
      paypass: "",
      timer: null,

      isActiveBrp: false,
      isReceive: false,

      decimalsMDS: 18,
      decimalsENFT: 18,
      decimalsBGG: 18,

      clickType: "",
      bggVisible: false,
      bggDepositLoading: false,
      avtiveVisible: false,

      auctionBlock: 0,

      levelList: [],
      hfhQuantity: 0
    };
  },
  components: { Pagination, BindEmail, Google, BindCode, sellDom, depositDom, claimDom, withdrawDom, Counts },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
      isLogin: (state) => state.isLogin,
      sets: (state) => state.sets,
      coinName1: (state) => state.coinName1,
      serverid: (state) => state.serverid,
    }),
    feeLable() {
      if (
        Number(this.withdrawForm.number) <= Number(this.withdraw_min_number)
      ) {
        return this.withdraw_min_number;
      } else {
        if (
          Number(this.withdraw_min_number) >=
          Number(this.withdrawForm.number * this.withdraw_fee)
        ) {
          return this.withdraw_min_number;
        } else {
          return this.withdraw_fee * 100 + "%";
        }
      }
    },
    total() {
      if (
        Number(this.withdrawForm.number) <= Number(this.withdraw_min_number)
      ) {
        return 0;
      }
      if (Number(this.withdrawForm.number) > Number(this.withdraw_min_number)) {
        if (
          Number(this.withdraw_min_number) >=
          Number(this.withdrawForm.number * this.withdraw_fee)
        ) {
          return this.withdrawForm.number - this.withdraw_min_number;
        } else {
          return (this.withdrawForm.number * (1 - this.withdraw_fee)).toFixed(
            2
          );
        }
      }
      // return this.eFix(
      //   Math.max(
      //     0,
      //     this.withdrawForm.number -
      //       Math.max(this.withdraw_min_number, this.withdraw_fee * this.withdrawForm.number)
      //   )
      // );
    },
  },
  watch: {
    addDragonId(n) {
      this.totalQuantityCanCreated = this.levelList.find(item => item.value == parseFloat(n)).total;
      this.remainQuantityCanCreated = this.levelList.find(item => item.value == parseFloat(n)).remaining;
      if(this.cardQuantity) {
        this.cardAmount = n * this.cardQuantity;
        let hfh = this.levelList.find(item => item.value == parseFloat(n)).hfh;
        this.hfhQuantity = hfh * this.cardQuantity;
      } else {
        this.hfhQuantity = 0;
      }
    },
    cardQuantity(n) {
      if(n) {
        this.cardAmount = n * this.addDragonId;
        let hfh = this.levelList.find(item => item.value == parseFloat(this.addDragonId)).hfh;
        this.hfhQuantity = hfh * n;
      }
    },
    serverid(n, o) {
      // console.log("serverid=>", n);
      if (n) {
        this.pageFun(1);
        this.getActivity();
      }
    },
    addDragonBox(n, o) {
      if (n) {
        this.addDragonId = this.levelList[0]?.value;
        this.isLoading = false;
      }
    },
    depositBox(n, o) {
      if (n) {
        this.balance = 0;
        this.getBalance();
        this.depositForm = {
          number: "",
        };
        this.isLoading = false;
      }
    },
    withdrawBox(n, o) {
      if (n) {
        console.log(n, this.withdrawType)
        this.balance = 0;
        this.withdraw_fee = 0;
        this.withdraw_balance = 0;
        if (this.withdrawType == 'BNB') {
          this.getBalanceBNB(this.withdrawType)
          this.withdrawForm = {
            number: "",
            address: this.infos.address,
            pass: "",
          };
        } else if (this.withdrawType == 'old_BNB') {
          this.getBalanceBNB(this.withdrawType)
          this.withdrawForm = {
            number: "",
            address: this.infos.address,
            pass: "",
          };
        } else {
          this.getBalance();
          this.withdrawForm = {
            number: "",
            address: "",
            pass: "",
          };
        }
        
        this.isLoading = false;
      }
    },
    setFundBox(n, o) {
      if (n) {
        this.paypass = "";
        this.isfundLoading = false
      }
    },
  },
  created() {
    this.tabCurrent = this.$storage.get("tabCurrent")
      ? this.$storage.get("tabCurrent")
      : 2;
  },
  mounted() {
    this.getDragonLevelList();
    if (this.serverid != "" || null) {
      // this.getBlock()
      this.pageFun(1);
      this.getActivity();
      this.getBalanceBNB('BNB');
      // this.getBalanceBNB('old_BNB'); 
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    handleCreateCard() {
      if(!this.infos.address) {
        return this.$message.error(this.$t('market.addDragonBox.connectWallet'));
      };
      this.addDragonBox = true
    },
    getDragonLevelList() {
      getLevelList().then(res => {
        this.levelList = res.data;
      });
    },
    toModify() {
      if (!this.modify_price) {
        return this.$message(this.$t('card.new_price'));
      };
      this.modifyLoading = true;
      let contract = new web3.eth.Contract(
        abiModifyPrice,
        this.sets.auction_contract_address
      );
      let amount = (this.modify_price * 1000000000000000000).toString();
      contract.methods.setCurrentPrice(this.tabCurrent == 1 ? this.sets.artifact_contract_address : this.sets.hero_contract_address, this.currentItem.tokenid, amount).send(
        {
          from: this.infos.address,
          gas: process.env.VUE_APP_GAS,
          gasPrice: process.env.VUE_APP_GAS_PRICE
        },
        (error, result) => {
          if (error) {
            this.modifyLoading = false;
            if (error.code == 4001) {
              this.$message("User denied transaction signature");
            } else this.$message(error.message);
          }
          if (result) {
            this.modify_price = '';
            this.modifyBox = false;
            this.modifyLoading = false;
            this.$message.success(this.$t('card.modifySuccess'));
          }
        }
      );
    },
    sixNum(num) {
      return Math.floor(num * 1000000) / 1000000
    },
    //0不操作，1可卖，2同步游戏数据中，3上链中, 4卖出确认中, 5挂卖中, 6更新游戏数据中，41上链失败, 40同步失败
    status(val) {
      switch (val) {
        case 0:
          return this.$t("card.status.operate");
          break;
        case 2:
          return this.$t("card.status.synchronizing");
          break;
        case 3:
          return this.$t("card.status.chain");
          break;
        case 4:
          return this.$t("card.status.confirmation");
          break;
        case 6:
          return this.$t("card.status.updategame");
          break;
        case 41:
          return this.$t("card.status.chainfail");
          break;
        case 40:
          return this.$t("card.status.syncfail");
          break;
        case 10:
          return this.$t("card.status.gameAssets"); //遊戲資產
          break;
        case 11:
          return this.$t("card.status.chain"); //上鏈中
          break;
        case 12:
          return this.$t("card.status.walletAssets"); //錢包資產
          break;
        case 14:
          return this.$t("card.status.synchronizing"); //同步遊戲數據中
          break;
        case 15:
          return this.$t("card.status.auctioned"); //合約資產
          break;
        default:
          break;
      }
    },
    // 更新：0 不可操作 10游戏资产(可邻取)， 11上链中(不可操作)， 12在钱包(可存入、可拍卖)， 13拍卖中不可操作, 14正在存入游戏中(不可操作), 15在合约(可领取,可拍卖) -->
    status2(val) {
      switch (val) {
        case 0:
          return this.$t("card.status.operate");
          break;
        case 11:
          return this.$t("card.status.chain");
          break;
        case 14:
          return this.$t("card.status.intogame");
          break;
        default:
          break;
      }
    },
    borderStatus(val, grade) {
      switch (val) {
        case 1:
          return 1;
          break;
        case 2:
          return 2;
          break;
        case 3:
          return 3;
          break;
        case 4:
          return 4;
          break;
        case 5:
          return (grade == "R" || grade == "SR" || grade == "SSR") ? "5" : "5_1";
          break;
        case 6:
        case 7:
        case 8:
        case 9:
          return 6;
          break;
        default:
          return 7;
          break;
      }
    },
    gradeStatus(val) {
      switch (val) {
        case "R":
          return "r";
        case "SR":
          return "sr";
        case "SSR":
          return "ssr";
        case "SSR+":
          return "ssrjia";
        case "SP":
          return "sp";
        default:
          break;
      }
    },
    eFix(num, n = 5) {
      let num1 = Number(num).toFixed(n);
      let num2 = num1.replace(/\.?0*$/gi, "");
      return num2;
    },
    async getBlock() {
      // console.log('getBlock=>',1)
      if (!this.sets.auction_contract_address) {
        setTimeout(() => {
          this.getBlock();
        }, 500);
        return false;
      }
      let auctionContract = new web3.eth.Contract(
        this.$store.state.auctionAbi,
        this.sets.auction_contract_address
      );
      auctionContract.methods
        .getExpireBlocks()
        .call({ from: this.infos.address })
        .then(async (block) => {
          console.log("getExpireBlocks=>", block);
          this.auctionBlock = block;
        });
    },
    // 元龍卡片列表
    pageFun(page) {
      this.list = [];
      switch (this.tabCurrent) {
        case 1:
          this.loading1 = true;
          getDragons({ page, pageSize: 12 }, { Serverid: this.serverid })
            .then((res) => {
              res.data.forEach((ele, index) => {
                ele.type = index + 1;
                ele.dragonname_tw =
                  textdata.data_text["4" + (ele.artifactid) + "00001"].s_Tw;
                ele.dragonname_en =
                  textdata.data_text["4" + (ele.artifactid) + "00001"].s_En;
                ele.dragonname_in =
                  textdata.data_text["4" + (ele.artifactid) + "00001"].s_Yn;
                ele.dragonname =
                  this._i18n.locale == "tw"
                    ? textdata.data_text["4" + (ele.artifactid) + "00001"].s_Tw
                    : this._i18n.locale == "en" ? textdata.data_text["4" + (ele.artifactid) + "00001"].s_En :
                      textdata.data_text["4" + (ele.artifactid) + "00001"].s_Yn;
              });
              // console.log(res.data);
              this.loading1 = false;
              this.list = res.data;
              this.meta = {
                current: 1,
                last: 1,
                total: 0,
              };
              // this.$storage.set("dragonsList", res.data);
            })
            .catch((err) => {
              this.loading1 = false;
            });
          break;
        case 2:
          this.loading1 = true;
          getcards({ page, pageSize: 12 }, { Serverid: this.serverid })
            .then((res) => {
              // console.log(res.data);
              this.loading1 = false;
              res.data.list.map((ele, i) => {
                // console.log(partner_data1.data_partner_base[ele.attrs.heroid]);
                // ele.assets_status = 14
                if (ele.attrs) {
                  if (ele.attrs.heroid) {
                    let name =
                      partner_data1.data_partner_base[ele.attrs.heroid].name;
                    ele.hero_name =
                      this._i18n.locale == "en"
                        ? textdata.data_text[name].s_En
                        : this._i18n.locale == "tw" ? textdata.data_text[name].s_Tw : textdata.data_text[name].s_Yn;
                  }
                } else {
                  ele.hero_name = "";
                }
              });
              this.list = res.data.list;
              // console.log(this.list);
              this.meta = {
                current: res.data.page || page,
                last: res.data.totalPage || 1,
                total: res.data.total,
              };
            })
            .catch((err) => {
              this.loading1 = false;
            });
          break;
      }
    },
    activeShow() {
      let that = this;
      if (!this.paypass) return this.$message(this.$t("fund.p_pass"));
      this.isfundLoading = true;
      if (this.funtype == '0') {
        activebggDragon(this.activeid, { paypass: this.paypass },
          { Serverid: this.$store.state.serverid })
          .then((res) => {
            if (res.code == 200) {
              this.isfundLoading = false;
              this.$message(this.$t("dragon.suc"));
              this.setFundBox = false;
              setTimeout(() => {
                this.pageFun(1, 1);
                this.$store.dispatch("Get_infos");
              }, 1000);
            }
            this.$store.dispatch("Get_websiteList");
          })
          .catch((err) => {
            this.isfundLoading = false;
          });
      } else {
        this.$axios.post(this.clickType == 'bgg' ? this.$api.bggactive : this.$api.brpactive, {
          password: this.paypass
        }, { Serverid: this.$store.state.serverid }).then(res => {
          if (res.code == 200) {
            this.isfundLoading = false;
            this.setFundBox = false;
            this.$message(this.$t('activate.ok'))
            this.$store.dispatch("Get_infos");
          }
        }).catch(err => {
          this.isfundLoading = false;
        })
      }
    },
    // 激活元龍
    async activeFun(item, type) {
      const isAccount = await this.$store.dispatch("Check_account", { t: this });
      if (!isAccount) return false;
      if (type == 'bgg') {
        // bgg
        this.activeid = item.id;
        if (this.infos.bgg < Number(item.bgg_price))
          return this.$message(this.$t("trans.err_able"));
        this.funtype = '0'
        this.setFundBox = true;
      } else {
        //brp
        // if (Number(this.infos.pre_coins) < Number(item.pre_price))
        //   return this.$message(this.$t("trans.err_able"));
        this.activeid2 = item.id;
        let contract = new web3.eth.Contract(
          this.$store.state.activeBrpAbi,
          this.sets.presale_coin_contract_address
        );
        this.isActiveBrp = true
        contract.methods.transfer(this.sets.active_presale_coin_to_address,
          '0x' + (item.pre_price).toString(16).replace(/\.\w+$/, ''))
          .send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE }, (error, result) => {
            if (error) {
              this.$message.error(error.message);
              this.isActiveBrp = false;
            } else if (result) {
              setTimeout(() => {
                this.$msgbox.alert(`<img alt="tip" src="${require('../assets/public/tip.png')}" style="height:62px;margin-bottom:20px;"><p>${this.$t('messageBox.activetip')}</p>`, "", {
                  confirmButtonText: this.$t("messageBox.confirm"),
                  center: true,
                  dangerouslyUseHTMLString: true,
                  callback: (action) => {
                    if (action === "confirm") {
                      this.isActiveBrp = false;
                      setTimeout(() => {
                        this.pageFun(1)
                        this.$store.dispatch("Get_infos");
                      }, 500);
                    }
                  },
                });
              }, 500);
            }
          })
      }
    },
    //领取元龍
    receive(item) {
      this.activeid3 = item.id
      this.isReceive = true
      this.$axios.post(this.$api.receiveDragon,
        { artifact_id: item.id }, { Serverid: this.$store.state.serverid }).then(res => {
          if (res.code == 200) {
            this.$msgbox.alert(`<img alt="tip" src="${require('../assets/public/ok.png')}" style="height:62px;margin-bottom:20px;"><p>${this.$t('messageBox.activetip2', { name: item.dragonname })}</p>`, "", {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              dangerouslyUseHTMLString: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.isReceive = false
                  setTimeout(() => {
                    this.pageFun(1)
                    this.$store.dispatch("Get_infos");
                  }, 500);
                }
              },
            });
          }
        })
    },
    // Take off the shelf
    async takeOff(item) {
      const isAccount = await this.$store.dispatch("Check_account", { t: this });
      if (!isAccount) return false;
      this.sellObj = item;
      this.$msgbox.confirm(
        this.$t("account.shelfor") + "#" + item.tokenid,
        this.$t("messageBox.tip2"),
        {
          confirmButtonText: this.$t("messageBox.confirm"),
          cancelButtonText: this.$t("messageBox.cancel"),
          center: true,
          callback: (action) => {
            if (action === "confirm") {
              cancelCards(
                item.order_id,
                { price: this.amount },
                { Serverid: this.$store.state.serverid }
              ).then((res) => {
                // let orderId = res.data.orderId;
                let contract = new web3.eth.Contract(
                  this.$store.state.cardsAbi,
                  this.sets.hero_contract_address
                );
                contract.methods.cancelAuction(item.tokenid).send(
                  {
                    from: this.infos.address,
                    gas: process.env.VUE_APP_GAS,
                    gasPrice: process.env.VUE_APP_GAS_PRICE
                  },
                  (error, result) => {
                    if (error) {
                      if (error.code == 4001) {
                        this.$message("User denied transaction signature");
                      } else this.$message(error.message);
                    }
                    let data = {
                      txhash: result,
                      status: result ? 1 : error.code === 4001 ? 2 : 0,
                      msg: result ? this.$t("messageBox.ok") : error.message,
                    };
                    cancelCardsCallback(item.order_id, data, {
                      Serverid: this.$store.state.serverid,
                    }).then((res) => {
                      // this.$emit("callBack", { type: "sellDom", val:result?'success': error.message });
                      this.callBack({
                        type: "sellDom",
                        val: result ? "success" : error.message,
                      });
                    });
                  }
                );
              });
            }
          },
        }
      );
    },
    async toSell(item, type) {
      if(!this.infos.address) {
        return this.$message.error(this.$t('market.addDragonBox.connectWallet'));
      };
      if (Number(item.address_type) == 1) {
        this.$msgbox.alert(
          this.$t('market.status.nftErrorTransferredOut'),
          this.$t("messageBox.tip"),
          {
            confirmButtonText: this.$t("messageBox.confirm"),
            center: true
          }
        );
      } else {
        const isAccount = await this.$store.dispatch("Check_account", { t: this });
        if (!isAccount) return false;
        if (type == 'dragon' && this.infos.vip_active_status == 0) return this.$message(this.$t('account.activetips'))
        this.sellObj = item;
        this.sellObj.type = type;
        this.$refs.sellDom.sellShow = true;
        console.log(this.sellObj, 56);
      }
    },
    async nftDeposit(item, type) {
      if (Number(item.address_type) == 1) {
        this.$msgbox.alert(
          this.$t('market.status.nftErrorTransferredOut'),
          this.$t("messageBox.tip"),
          {
            confirmButtonText: this.$t("messageBox.confirm"),
            center: true
          }
        );
      } else {
        const isAccount = await this.$store.dispatch("Check_account", { t: this });
        if (!isAccount) return false;
        this.sellObj = item;
        this.sellObj.type = type;
        this.$refs.depositDom.sellShow = true;
      }
    },
    async nftClaim(item, type) {
      if(!this.infos.address) {
        return this.$message.error(this.$t('market.addDragonBox.connectWallet'));
      };
      // const isAccount = await this.$store.dispatch("Check_account", { t: this });
      // if (!isAccount) return false;
      this.sellObj = item;
      this.sellObj.type = type;
      this.$refs.claimDom.sellShow = true;
    },
    async nftWithdraw(item, type) {
      if(!this.infos.address) {
        return this.$message.error(this.$t('market.addDragonBox.connectWallet'));
      };
      const isAccount = await this.$store.dispatch("Check_account", { t: this });
      if (!isAccount) return false;
      this.sellObj = item;
      this.sellObj.type = type;
      this.$refs.withdrawDom.sellShow = true;
    },

    change(index) {
      if (this.tabCurrent == index) return;
      this.tabCurrent = index;
      this.$storage.set("tabCurrent", index);
      this.pageFun(1);
    },
    //详情
    toDetail(item, type) {
      if (type == "dragon") {
        // if (item.status == 1) {
        //已激活
        this.$router.push({
          path: "/dragonDetail",
          query: {
            item: JSON.stringify(item),
            id: item.id,
          },
        });
        // } else {
        //   return this.$message(this.$t("dragon.notactive"));
        // }
      }
      if (type == "card") {
        // console.log(item.assets_status);
        // if (item.assets_status != 12 && item.assets_status != 13) {
        //   return this.$message(this.status(item.assets_status));
        // }
        this.$router.push({
          path: "/cardDetail",
          query: {
            type: "account",
            id: item.game_symbol,
          },
        });
      }
    },

    addressFilter(str) {
      if (!str) return "--";
      const str1 = str.substring(0, 5);
      const str2 = str.substring(str.length - 4, str.length);
      return str1 + "..." + str2;
    },
    // 复制
    copyFun(dom, value) {
      if (!value) return this.$message("复制内容为空");
      var clipboard = new Clipboard(dom);
      clipboard.on("success", (e) => {
        this.$message({
          message: "复制成功",
          type: "success",
        });
        e.clearSelection();
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message("复制失败");
        clipboard.destroy();
      });
    },
    navigate() {
      this.$router.push("/activity");
    },
    jump() {
      window.open(process.env.VUE_APP_BSC + "/address/" + this.infos.address);
    },
    // 存入
    async toDeposit(type) {
      if(!this.infos.address) {
        return this.$message.error(this.$t('market.addDragonBox.connectWallet'));
      };
      const isAccount = await this.$store.dispatch("Check_account", { t: this });
      if (!isAccount) return false;
      this.depositType = type;
      // 游戏在线状态：0-不在线；1-在线
      if (this.infos.is_online == 1) {
        return this.$message(this.$t("element.online1"));
      }
      let arr = [];
      let isactive;
      if (this.depositType == "MDS") {
        // if (this.$storage.get("dragonsList")) {
        //   let list = this.$storage.get("dragonsList");
        //   list.forEach((ele) => {
        //     arr.push(ele.status);
        //     // console.log(arr.indexOf(1)); //1是已激活
        //     if (arr.indexOf(1) == -1) {
        //       return (isactive = false);
        //     } else {
        //       return (isactive = true);
        //     }
        //   });
        // }
        // if (!isactive) {
        //   this.$message("请先激活元龍");
        // } else {
        // if (this.infos.vip_active_status == 0) return this.$message(this.$t('account.activetips'))
        this.depositBox = true;
        // }
      } else {
        this.depositBox = true;
      }
    },
    // 提出
    async toWithdraw(type) {
      if(!this.infos.address) {
        return this.$message.error(this.$t('market.addDragonBox.connectWallet'));
      };
      const isAccount = await this.$store.dispatch("Check_account", { t: this });
      if (!isAccount) return false;
      this.withdrawType = type;
      if (type == 'BNB' || type == 'old_BNB') {
        this.withdrawBox = true;
      } else if (type == "BGG") {
        // type == "ENFT" || type == "BGG"
        if (this.infos.is_online == 1) {
          return this.$message(this.$t("element.online2"));
        }
        this.withdrawBox = true;
      } else {
        if (this.infos.is_online == 1) {
          return this.$message(this.$t("element.online2"));
        }
        let arr = [];
        let isactive;
        if (this.$storage.get("dragonsList")) {
          let list = this.$storage.get("dragonsList");
          list.forEach((ele) => {
            arr.push(ele.status);
            // console.log(arr.indexOf(1)); //1是已激活
            if (arr.indexOf(1) == -1) {
              return (isactive = false);
            } else {
              return (isactive = true);
            }
          });
        }
        // if (!isactive) {
        //   this.$message("请先激活元龍");
        // } else {
        this.withdrawBox = true;
        // }
      }
    },
    //活动列表
    getActivity() {
      this.loading2 = true;
      this.$axios
        .get(
          this.$api.getActivityList,
          { page: 1, pageSize: 5 },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          //  1-充币,   2- 提币, 3-买装备 ,  4-卖装备 ,  5-取消卖装备 ,  6-卖装备成功 ,
          //  7-提币冻结 ,  8-提币驳回 ,  9-激活元龍  , 11-英雄上链  , 12-神器上链
          //  14-提币brc冻结, 15-提币brc驳回, 16-充币brc, 17-提币brc
          res.data.list.map((item) => {
            if (item.two_amount) item.two_amount = Math.floor(item.two_amount * Math.pow(10, 6)) / Math.pow(10, 6);
            if (item.amount) item.amount = Math.floor(item.amount * Math.pow(10, 6)) / Math.pow(10, 6);
            if (item.two_address)
              item.two_address = this.addressFilter(item.two_address);
            if (item.assets && item.assets.game_assets_id) {
              if (item.assets.game_assets_id.toString().length > 1) {
                let name = partner_data1.data_partner_base[item.assets.game_assets_id].name;
                item.name =
                  this._i18n.locale == "tw"
                    ? textdata.data_text[name].s_Tw
                    : textdata.data_text[name].s_En;
              } else {
                item.name =
                  this._i18n.locale == "tw"
                    ? textdata.data_text["4" + (item.assets.game_assets_id) + "00001"].s_Tw
                    : textdata.data_text["4" + (item.assets.game_assets_id) + "00001"].s_En;
              }
            }
            switch (item.type) {
              case 1:
                item.assets = {
                  type: 0,
                };
                break;
              case 2:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  item.amount2 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_fee_ratio)
                  ) {
                    item.amount2 =
                      item.amount - this.sets.withdraw_fee_min_number;
                  } else {
                    item.amount2 = (
                      item.amount *
                      (1 - this.sets.withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                // item.amount2 = item.amount - this.sets.withdraw_fee_min_number;
                item.assets = {
                  type: 0,
                };
              case 3:
                item.assets = {
                  type: 1,
                };
                break;
              case 4:
                item.assets = {
                  type: 1,
                };
                break;
              case 7:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  item.amount7 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_fee_ratio)
                  ) {
                    item.amount7 =
                      item.amount - this.sets.withdraw_fee_min_number;
                  } else {
                    item.amount7 = (
                      item.amount *
                      (1 - this.sets.withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                // item.amount7 = item.amount - this.sets.withdraw_fee_min_number;
                item.assets = {
                  type: 0,
                };
              case 8:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  item.amount8 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_fee_ratio)
                  ) {
                    item.amount8 =
                      item.amount - this.sets.withdraw_fee_min_number;
                  } else {
                    item.amount8 = (
                      item.amount *
                      (1 - this.sets.withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                // item.amount8 = item.amount - this.sets.withdraw_fee_min_number;
                item.assets = {
                  type: 0,
                };
                break;
              case 10:
                try {
                  // item.img = state.img["hero" + item.role_id || ""];
                  if (item.assets) item.tokenid = item.assets.tokenid;
                  if (item.user_role)
                    item.nickname = item.user_role.game_nickname;
                  item.nftname = computed(() => t("hero.name." + item.role_id));
                } catch (e) { }
                break;
              case 5:
                if (item.assets.type == 1)
                  // item.heroname = computed(() =>
                  //   t("hero.name." + item.hero_templ_id)
                  // );
                  break;
              case 6:
              case 9:
                item.assets = {
                  type: 0,
                };
                break;
              case 11:
                item.assets = {
                  type: 0,
                };
                break;
              case 12:
                item.assets = {
                  type: 0,
                };
                break;
              case 14:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  item.amount14 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_brc_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_brc_fee_ratio)
                  ) {
                    item.amount14 =
                      item.amount - this.sets.withdraw_brc_fee_min_number;
                  } else {
                    item.amount14 = (
                      item.amount *
                      (1 - this.sets.withdraw_brc_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 15:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  item.amount15 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_brc_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_brc_fee_ratio)
                  ) {
                    item.amount15 =
                      item.amount - this.sets.withdraw_brc_fee_min_number;
                  } else {
                    item.amount15 = (
                      item.amount *
                      (1 - this.sets.withdraw_brc_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 16:
                item.assets = {
                  type: 0,
                };
                break;
              case 17:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  item.amount17 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_brc_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_brc_fee_ratio)
                  ) {
                    item.amount17 =
                      item.amount - this.sets.withdraw_brc_fee_min_number;
                  } else {
                    item.amount17 = (
                      item.amount *
                      (1 - this.sets.withdraw_brc_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 19:
              case 20:
              case 30:
              case 31:
              case 32:
              case 33:
              case 34:
              case 35:
              case 36:
                item.amount = Number(item.amount);
                break;
              case 51:
                if (
                  Number(item.amount) <=
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  item.amount51 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  if (
                    Number(this.sets.bgg_withdraw_min_fee) >=
                    Number(item.amount * this.sets.bgg_withdraw_fee_ratio)
                  ) {
                    item.amount51 =
                      item.amount - this.sets.bgg_withdraw_min_fee;
                  } else {
                    item.amount51 = (
                      item.amount *
                      (1 - this.sets.bgg_withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 53:
                if (
                  Number(item.amount) <=
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  item.amount53 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  if (
                    Number(this.sets.bgg_withdraw_min_fee) >=
                    Number(item.amount * this.sets.bgg_withdraw_fee_ratio)
                  ) {
                    item.amount53 =
                      item.amount - this.sets.bgg_withdraw_min_fee;
                  } else {
                    item.amount53 = (
                      item.amount *
                      (1 - this.sets.bgg_withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 54:
                if (
                  Number(item.amount) <=
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  item.amount54 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  if (
                    Number(this.sets.bgg_withdraw_min_fee) >=
                    Number(item.amount * this.sets.bgg_withdraw_fee_ratio)
                  ) {
                    item.amount54 =
                      item.amount - this.sets.bgg_withdraw_min_fee;
                  } else {
                    item.amount54 = (
                      item.amount *
                      (1 - this.sets.bgg_withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 52:
                item.assets = {
                  type: 0,
                };
                break;
              case 60:
                item.assets = {
                  type: 0,
                };
                break;
              default:
                break;
            }
          });
          this.activityList = res.data.list;
          this.loading2 = false;
        })
        .catch((err) => {
          this.loading2 = false;
        });
    },
    //查询精度
    getdecimals() {
      if (this.depositType == "MDS" || this.withdrawType == "MDS") {
        let myContract20 = new web3.eth.Contract(abiArray20, this.sets.coin_contract_address);
        myContract20.methods
          .decimals()
          .call({ from: this.infos.address }, (err, res) => {
            console.log("decimalsMDS=>", res);
            this.decimalsMDS = res;
          });
      } else if (this.depositType == "BGG" || this.withdrawType == "BGG") {
        // bgg
        let myContractBGG = new web3.eth.Contract(abiArraybgg, this.sets.bgg_contract_address);
        myContractBGG.methods
          .decimals()
          .call({ from: this.infos.address }, (err, res) => {
            console.log("decimalsBGG=>", res);
            this.decimalsBGG = res;
          });
      } else if (this.withdrawType == "ENFT") {
        // this.depositType == "ENFT" || this.withdrawType == "ENFT"
        console.log(this.sets.artifact_fee_contract_address);
        let contract = new web3.eth.Contract(
          this.$store.state.activeAbi,
          this.sets.artifact_fee_contract_address
        );
        console.log("contract", contract);
        contract.methods
          .decimals()
          .call({ from: this.infos.address }, (err, res) => {
            console.log("decimalsENFT=>", res);
            this.decimalsENFT = res;
          });
      }
    },
    async getBalance() {
      this.disabled = true;
      this.isDisable = true;
      // console.log(this.$store.state.serverid);
      let list = JSON.parse(this.$store.state.userServeList);
      list.map((ele) => {
        if (ele.server_id == this.$store.state.serverid) {
          if (ele.nickname) this.account = ele.title + "-" + ele.nickname;
          else this.account = ele.title;
        }
      });
      await this.$store.dispatch("Get_setting");
      await this.getdecimals()
      this.withdraw_min_number_bgg = this.sets.bgg_withdraw_min_num
      this.min = this.withdrawType == "MDS" ? this.sets.charge_min_num
        : this.withdrawType == "BGG" ? this.sets.bgg_charge_min_num : this.sets.charge_brc_min_amount; //最小
      this.withdraw_fee = this.withdrawType == "MDS"
        ? this.sets.withdraw_fee_ratio //提BRC百分比手续费
        : this.withdrawType == "BGG" ? this.sets.bgg_withdraw_fee_ratio : this.sets.withdraw_brc_fee_ratio; //提币百分比手续费（小数形式）
      this.withdraw_min_number = this.withdrawType == "MDS"
        ? this.sets.withdraw_fee_min_number //提BRC最低手续费
        : this.withdrawType == "BGG" ? this.sets.bgg_withdraw_min_fee : this.sets.withdraw_brc_fee_min_number; //提现最小手续费,金币个数
      this.isDisable = false
      if (this.depositType == "MDS" || this.withdrawType == "MDS") {
        let myContract20 = new web3.eth.Contract(abiArray20, this.sets.coin_contract_address);
        myContract20.methods
          .balanceOf(this.infos.address)
          .call({ from: this.infos.address })
          .then((res) => {
            this.balance = Math.floor(BigNumber(res).div(Math.pow(10, this.decimalsMDS)))
            console.log("balanceBDD=>", this.balance);
            this.disabled = false;
          });
      } else if (this.depositType == "BGG" || this.withdrawType == "BGG") {
        let myContractBGG = new web3.eth.Contract(abiArraybgg, this.sets.bgg_contract_address);
        myContractBGG.methods
          .balanceOf(this.infos.address)
          .call({ from: this.infos.address })
          .then((res) => {
            this.balance = Math.floor(BigNumber(res).div(Math.pow(10, this.decimalsBGG)))
            console.log("balanceBGG=>", this.balance);
            this.disabled = false;
          });
      } else {
        // this.withdraw_balance = Number(this.infos.coins2);
        let contract = new web3.eth.Contract(
          this.$store.state.activeAbi,
          this.sets.artifact_fee_contract_address
        );
        // console.log("contract", contract);
        contract.methods
          .balanceOf(this.infos.address)
          .call({
            from: this.infos.address,
          })
          .then((res) => {
            // this.balance = BigNumber(res).toFixed()
            this.balance = Math.floor(BigNumber(res).div(Math.pow(10, this.decimalsENFT)))
            console.log("balanceENFT=>", this.balance);
            this.disabled = false;
          });
      }
    },
    toFixeds(num, decimal) {
      num = num.toString();
      let index = num.indexOf('.');
      if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
      } else {
        num = num.substring(0)
      }
      return parseFloat(num).toFixed(decimal)
    },
    async getBalanceBNB(coin) {
      this.isDisable = true;
      let list = JSON.parse(this.$store.state.userServeList);
      list.map((ele) => {
        if (ele.server_id == this.$store.state.serverid) {
          if (ele.nickname) this.account = ele.title + "-" + ele.nickname;
          else this.account = ele.title;
        }
      });
      await this.$store.dispatch("Get_setting");
      this.min = 0; //最小
      this.withdraw_fee = 0
      this.withdraw_min_number = 0
      let auctionContract = new web3.eth.Contract(this.$store.state.auctionAbi, coin == 'BNB' ? this.sets.auction_contract_address :this.sets.old_auction_contract_address)
      auctionContract.methods.balanceOf(
        this.infos.address
      ).call({ from: this.infos.address }).then(balance => {
        let bla = BigNumber(balance).div(Math.pow(10, 18)).toFixed()
        // this.balanceBNB = this.sixNum(Math.floor((BigNumber(balance).div(Math.pow(10, 18)).toFixed()) * Math.pow(10, 6)) / Math.pow(10, 6));
        if(coin == 'BNB') {
          this.balanceBNB = parseFloat(this.toFixeds(bla, 6))
          console.log("balanceBNB=>", this.balanceBNB);
        } else {
          this.old_balanceBNB = parseFloat(this.toFixeds(bla, 6))
          console.log("old_balanceBNB=>", this.old_balanceBNB);
        }  
        this.isDisable = false;
      })
    },
    async createCallback(id, txhash, type) {
      let params = {
        id,
        txhash,
        type
      };   
      try {
        await createCard_callback(params);
        if(type == 1) {
         return this.$message.success(this.$t('market.addDragonBox.successTxs'));
        };
        // this.$message.success(this.$t('market.addDragonBox.failedTxs'));
      } catch (error) {
        this.$message.error(this.$t('market.addDragonBox.failedTxs'));
      }
    },
    async addDragonFun() {
      if(!this.cardQuantity || parseFloat(this.cardQuantity) <= 0) {
        return this.$message.error(this.$t('market.addDragonBox.idPlaceholder'));
      };
      let params = {
        type: this.levelList.find(item => item.value == this.addDragonId)?.key,
        num: this.cardQuantity
      };
    let res = await createCard(params);
    let createdCardId = res.data.id;
      this.isLoading = true;
      let contract = new web3.eth.Contract(
          this.$store.state.activeBrpAbi,
          this.sets.usdt_contract_address
        );
        let amount = web3.utils.toWei(this.cardAmount.toString());
        contract.methods.transfer(this.sets.usdt_in_address, amount)
          .send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE }, (error, result) => {
            if (error) {
              this.$message.error(error.message);
              this.isLoading = false;
              this.createCallback(createdCardId, '', 2)
            } else if (result) {
              console.log('hash=====', result);
              this.addDragonBox = false;
              setTimeout(() => {
                this.$msgbox.alert(`<img alt="tip" src="${require('../assets/public/tip.png')}" style="height:62px;margin-bottom:20px;"><p>${this.$t('messageBox.activetip')}</p>`, "", {
                  confirmButtonText: this.$t("messageBox.confirm"),
                  center: true,
                  dangerouslyUseHTMLString: true,
                  callback: (action) => {
                    if (action === "confirm") {
                      this.isLoading = false;
                      setTimeout(() => {
                        this.pageFun(1)
                        this.$store.dispatch("Get_infos");
                        this.createCallback(createdCardId, result, 1)
                      }, 500);
                    }
                  },
                });
              }, 500);
            }
          })
    },
    //存入确认
    depositFun() {
      if (!this.depositForm.number)
        return this.$message(this.$t("trans.p_number"));
      if (this.depositForm.number - this.min < 0)
        return this.$message(this.$t("trans.err_min") + this.min);
      if (this.depositForm.number - this.balance > 0)
        return this.$message(this.$t("trans.err_able"));
      this.isLoading = true;
      this.$axios
        .post(
          this.depositType == "MDS" ? this.$api.charge : this.depositType == "BGG" ? this.$api.chargebgg : this.$api.charge2,
          { amount: this.depositForm.number },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          if (this.depositType == "MDS") {
            let address = this.sets.charge_coin_in_address;
            let amount = '0x' + BigNumber(this.depositForm.number).times(Math.pow(10, this.decimalsMDS)).toString(16).replace(/\.\w+$/, "");
            myContract20.methods
              .transfer(address, amount)
              .send(
                { from: this.infos.address, to: address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE },
                (error, result) => {
                  this.$axios
                    .post(
                      this.$api.charge + "/" + res.data.id,
                      {
                        txhash: result,
                        status: result ? 1 : error.code === 4001 ? 2 : 0,
                        msg: result ? this.$t("messageBox.ok") : error.message,
                      },
                      { Serverid: this.$store.state.serverid }
                    )
                    .then((res2) => {
                      this.isLoading = false;
                    })
                    .catch((err2) => {
                      this.isLoading = false;
                    });
                  if (error) this.$message(error.message);
                  else if (result) {
                    this.$store.dispatch("Get_infos");
                    this.depositBox = false;
                    this.$msgbox.alert(this.$t("trans.ok"), "", {
                      confirmButtonText: this.$t("trans.to"),
                      center: true,
                      callback: (action) => {
                        if (action === "confirm") {
                          this.$router.push("/activity");
                        }
                      },
                    });
                  }
                }
              );
          } else if (this.depositType == "BGG") {
            let address = this.sets.bgg_chargein_address;
            let myContractBGG = new web3.eth.Contract(abiArraybgg, this.sets.bgg_contract_address);
            // let amount = '0x' +  (this.depositForm.number * Math.pow(10, this.decimalsBGG)).toString(16).replace(/\.\w+$/, '')
            let amount = '0x' + BigNumber(this.depositForm.number).times(Math.pow(10, this.decimalsBGG)).toString(16).replace(/\.\w+$/, "");
            myContractBGG.methods
              .transfer(address, amount)
              .send(
                { from: this.infos.address, to: address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE },
                (error, result) => {
                  this.$axios
                    .post(
                      this.$api.chargebgg + "/" + res.data.bgg_record_id,
                      {
                        txhash: result,
                        status: result ? 1 : error.code === 4001 ? 2 : 0,
                        msg: result ? this.$t("messageBox.ok") : error.message,
                      },
                      { Serverid: this.$store.state.serverid }
                    )
                    .then((res2) => {
                      this.isLoading = false;
                    })
                    .catch((err2) => {
                      this.isLoading = false;
                    });
                  if (error) this.$message(error.message);
                  else if (result) {
                    this.$store.dispatch("Get_infos");
                    this.depositBox = false;
                    this.$msgbox.alert(this.$t("trans.ok"), "", {
                      confirmButtonText: this.$t("trans.to"),
                      center: true,
                      callback: (action) => {
                        if (action === "confirm") {
                          this.$router.push("/activity");
                        }
                      },
                    });
                  }
                }
              );
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    //提出确认
    withdrawFun() {
      if (!this.infos.email) return this.$message(this.$t("email.p_set"));
      if (this.infos.has_paypass != 1)
        return this.$message(this.$t("fund.p_set"));
      if (!this.withdrawForm.number)
        return this.$message(this.$t("trans.p_number"));
      if (Number(this.withdrawForm.number) <= 0)
        return this.$message(this.$t("trans.p_numberda"));
      if (
        this.withdrawType == "MDS" &&
        this.withdrawForm.number - this.infos.coins > 0
      )
        return this.$message(this.$t("trans.err_able"));
      // if (
      //   this.withdrawType == "BGG" &&
      //   this.withdrawForm.number - this.infos.bgg > 0
      // )
      //   return this.$message(this.$t("trans.err_able"));
      // if (
      //   this.withdrawType == "ENFT" &&
      //   this.withdrawForm.number - this.infos.coins2 > 0
      // )
      //   return this.$message(this.$t("trans.err_able"));
      if (this.withdrawType == "BGG") {
        if (this.withdrawForm.number < Number(this.withdraw_min_number_bgg))
          return this.$message(
            this.$t("trans.min_number") + ` ${this.withdraw_min_number_bgg}`
          );
      } else {
        if (this.withdrawForm.number < Number(this.withdraw_min_number))
          return this.$message(
            this.$t("trans.min_number") + ` ${this.withdraw_min_number}`
          );
      }
      if (!this.withdrawForm.pass) return this.$message(this.$t("fund.p_pass"));
      this.isLoading = true;
      if (this.withdrawType == "BNB" || this.withdrawType == "old_BNB") {
        if (Number(this.withdrawForm.number) > Number(this.withdrawType == "BNB" ?this.balanceBNB :this.old_balanceBNB)) {
          this.isLoading = false;
          return this.$message(this.$t("trans.err_able"))
        };
        this.withdrawForm.number = this.sixNum(this.withdrawForm.number)
        let auctionContract = new web3.eth.Contract(this.$store.state.auctionAbi, this.withdrawType == "BNB" ?this.sets.auction_contract_address :this.sets.old_auction_contract_address)
        // console.log('withdraw=>',1)  .toFixed()
        let amount = BigNumber(this.withdrawForm.number).times(Math.pow(10, 18)).toFixed()
        console.log(this.withdrawForm.number);
        auctionContract.methods.withdraw(amount).send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE }).then((result, error) => {
          console.log('withdraw11=>', result)
          console.log('withdraw22=>', error)
          if (error) this.$message(error.message);
          this.withdrawBox = false;
          this.isLoading = false;
          // console.log('balanceOf=>',1)
          auctionContract.methods.balanceOf(
            this.infos.address
          ).call({ from: this.infos.address }).then(balance => {
            if(this.withdrawType == "BNB") {
              console.log('balanceOfNow=>', balance)
              this.balanceBNB = (BigNumber(balance).div(Math.pow(10, 18)).toFixed());
            } else {
              console.log('balanceOfOld=>', balance)
              this.old_balanceBNB = (BigNumber(balance).div(Math.pow(10, 18)).toFixed());
            } 
          })
        }).catch(err => {
          this.isLoading = false;
        })
      }
      else this.$axios
        .post(
          this.withdrawType == "MDS" ? this.$api.withdraw : this.withdrawType == "BGG" ? this.$api.withdrawbgg : this.$api.withdraw2,
          {
            address: this.infos.address,
            amount: Number(this.withdrawForm.number),
            pay_password: this.withdrawForm.pass,
          },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          this.withdrawBox = false;
          this.isLoading = false;
          this.$msgbox.alert(this.$t("trans.ok"), "", {
            confirmButtonText: this.$t("trans.to"),
            center: true,
            callback: (action) => {
              if (action === "confirm") {
                this.$router.push("/activity");
              }
            },
          });
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    emailFun() {
      this.$axios
        .post(
          this.$api.sendCode,
          { email: this.emailForm.email },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$refs.googleDom.captchaShow = false;
            this.$refs.bindCodeDom.codeShow = true;
          }
        });
    },
    otherFun() {
      this.withdrawShow = false;
      this.$router.push("/setting");
    },
    //回调
    callBack({ type, val }) {
      switch (type) {
        case "bindEmail":
          // this.$refs.googleDom.captchaShow = true;
          this.emailForm = val;
          this.emailFun();
          break;
        case "google":
          this.$refs.bindEmailDom.emailShow = false;
          if (val) this.emailFun();
          break;
        case "CheckCode":
          this.$refs.bindEmailDom.bindEmailFun(val);
          break;
        case "HideCode":
          this.$refs.bindCodeDom.loading = false;
          this.$refs.bindCodeDom.codeShow = false;
          if (val) this.$store.dispatch("Get_infos");
          break;
        case "sellDom":
          console.log(val);
          this.$refs.sellDom.sellShow = false;
          if (val != "success") return this.$message(val);
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.shelgsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.tabCurrent = this.sellObj.type == 'card' ? 2 : 1;
                  this.pageFun(1);
                  this.getActivity();
                }
              },
            }
          );
          break;
        case "reAuctionDom":
        case "auctionDom":
          console.log(val);
          this.$refs.sellDom.sellShow = false;
          if (val != "success") return this.$message(val);
          // 已成功起拍，請等待鏈上確認！
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.auctionsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.tabCurrent = this.sellObj.type == 'card' ? 2 : 1;
                  this.pageFun(1);
                  this.getActivity();
                }
              },
            }
          );
          break;
        case "buyDom":
          console.log(val);
          this.$refs.sellDom.sellShow = false;
          if (val != "success") return this.$message(val);
          // 已成功出價，請等待鏈上確認！
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.reAuctionsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.tabCurrent = this.sellObj.type == 'card' ? 2 : 1;
                  this.pageFun(1);
                  this.getActivity();
                }
              },
            }
          );
          break;
        case "depositDom":
          console.log(val);
          this.$refs.depositDom.sellShow = false;
          if (val != "success") return this.$message(val);
          // 已成功存入/轉入遊戲，請等待鏈上確認！
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.sellObj.type == 'card' ? this.$t("account.depositsuc") : this.$t("account.depositsuc2"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.tabCurrent = this.sellObj.type == 'card' ? 2 : 1;
                  this.pageFun(1);
                  this.getActivity();
                }
              },
            }
          );
          break;
        case "claimDom":
          console.log(val);
          this.$refs.claimDom.sellShow = false;
          if (val != "success") return this.$message(val);
          // 已成功領取/轉入錢包
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.sellObj.type == 'card' ? this.$t("account.claimsuc") : this.$t("account.claimsuc2"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.tabCurrent = this.sellObj.type == 'card' ? 2 : 1;
                  this.pageFun(1);
                  this.getActivity();
                }
              },
            }
          );
          break;
        case "withdrawDom":
          console.log(val);
          this.$refs.withdrawDom.sellShow = false;
          if (val != "success") return this.$message(val);
          this.$msgbox.alert(
            "#" + this.sellObj.tokenid + this.$t("account.withdrawsuc"),
            this.$t("messageBox.tip2"),
            {
              confirmButtonText: this.$t("messageBox.confirm"),
              center: true,
              callback: (action) => {
                if (action === "confirm") {
                  this.tabCurrent = 2;
                  this.pageFun(1);
                  this.getActivity();
                }
              },
            }
          );
          break;
        default:
          break;
      }
    },
    // 充值brp
    desBRP() {
      let contract = new web3.eth.Contract(
        this.$store.state.activeBrpAbi,
        this.sets.presale_coin_contract_address
      );
      this.bggDepositLoading = true
      let amount = '0x' + BigNumber(this.sets.brp_vip_active_amount).toString(16).replace(/\.\w+$/, '')
      console.log("amount", amount);
      contract.methods.transfer(this.sets.active_presale_coin_to_address, amount)
        .send({ from: this.infos.address, gas: process.env.VUE_APP_GAS, gasPrice: process.env.VUE_APP_GAS_PRICE }, (error, result) => {
          console.log("result", result);
          business({ txhash: result }, { Serverid: this.$store.state.serverid })
          if (error) {
            this.$message.error(error.message);
            this.bggDepositLoading = false;
            this.bggVisible = false
          } else if (result) {
            setTimeout(() => {
              this.$msgbox.alert(`<img alt="tip" src="${require('../assets/public/tip.png')}" style="height:62px;margin-bottom:20px;"><p>${this.$t('messageBox.activetip')}</p>`, "", {
                confirmButtonText: this.$t("messageBox.confirm"),
                center: true,
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                  if (action === "confirm") {
                    this.bggDepositLoading = false;
                    this.bggVisible = false
                    setTimeout(() => {
                      this.$store.dispatch("Get_infos");
                    }, 500);
                  }
                },
              });
            }, 500);
          }
        })
    },
    onBeginAvtive(type) {
      if (!this.infos.email) {
        return this.$msgbox.alert(
          this.$t("messageBox.emailTip"),
          this.$t("messageBox.tip"),
          { confirmButtonText: this.$t("messageBox.close"), center: true }
        );
      }
      // if (type == 'bgg' && Number(this.infos.bgg) < Number(this.sets.mds_vip_active_amount)) return this.$message(this.$t('trans.err_able'));
      // 先判断brp余额是否大于0 ，小于=0 是 拉起钱包充值，大于0 是调用激活vip接口
      if (type == 'brp' && Number(this.infos.pre_coins) <= 0) return this.bggVisible = true
      this.clickType = type
      this.avtiveVisible = true
    },
    // 激活确认
    async onActive() {
      this.avtiveVisible = false
      this.funtype = '1'
      this.setFundBox = true;
    },
    // 元龍操作
    dragonFun(type) {
      // 0-未激活，1-已激活
      if (this.infos.vip_active_status == 0) return this.$message(this.$t('account.activetips'))
    },
    getAll() {
      // withdrawForm.number = withdrawType == 'MDS' ? infos.coins : withdrawType == 'BNB' ? sixNum(balanceBNB) : withdrawType == 'BGG' ? Number(infos.bgg) : infos.coins2
      if(this.withdrawType == 'MDS') {
        this.withdrawForm.number = this.infos.coins;
      } else if(this.withdrawType == 'BNB') {
        this.withdrawForm.number = this.sixNum(this.balanceBNB);
      } else if(this.withdrawType == 'old_BNB') {
        this.withdrawForm.number = this.sixNum(this.old_balanceBNB);
      } else if(this.withdrawType == 'BGG') {
        this.withdrawForm.number = Number(this.infos.bgg);
      } else {
        this.withdrawForm.number = infos.coins2;
      }
    }
  },
};
</script>

<style>
  .myCardSelector {
    border: none;
    background: rgba(41, 43, 57, 1) !important;
  }
  .el-select-dropdown__item {
    color: #fff!important;
    font-size: 16px;
  }
  .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background: transparent!important;
  }
  .el-select-dropdown__item .seleted {
    background: transparent!important;
  }
  .el-popper[x-placement^=bottom] .popper__arrow::after{
    border-bottom-color: transparent!important;
  }
  .el-popper .popper__arrow, .el-popper .popper__arrow::after{
    border-style: none !important;
  }
</style>

<style lang="scss" scoped>
/deep/ .el-dialog .el-input__inner {
  // padding-right: 40px;
  border-radius: 4px 0 0 4px !important;
}

/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #0f1016 !important;
  border: none;
}

.tab-header-left {
  // width: 20%;
}

.tab-header-right {
  // width: 20%;
}

.blue-btn {
  height: 28px;
  padding: 15px 15px;
  border-radius: 4px;
  font-size: 11px;
  border: 1px solid #09AFE9;
  color: #09AFE9;
}

.acrive_box {
  width: 100%;
  height: 80px;
  background: #0f131b;
  margin-bottom: 15px;
  padding-left: 20px;
  background: url("../assets/account/bj.png") no-repeat;
  background-size: 100% 100%;
  position: relative;

  .right_icon {
    min-width: 82px;
    height: 20px;
    line-height: 20px;
    background: #67c23a;
    border-radius: 0px 2px 0px 5px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 11px;
    padding: 0 5px;
  }

  .bgblack {
    width: 173px;
    height: 40px;
    background: url("../assets/account/bj_b.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;

    p {
      font-size: 10px;
      color: #f49314;
    }
  }
}

.acrive_box_jihuo {
  background: url("../assets/account/bj-jijihuo.png") no-repeat;
  background-size: 100% 100%;
}

.btn_group {
  flex: 1;

  .btn {
    width: 48%;
    height: 28px;
    border-radius: 4px;
    font-size: 11px;
  }

  .rp_btn {
    background: #317f6e;
  }

  .bgg_btn {
    background: #f49314;
  }
}

.setEmail {
  width: 100%;
  height: 130px;
  background: #37b497;
  border-radius: 4px;
  padding-left: 20px;
  margin-bottom: 30px;

  span {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
  }

  .setup {
    width: 245px;
    height: 32px;
    background: #317f6e;
    margin-top: 15px;
    border-radius: 4px;
    padding-left: 10px;

    span {
      font-size: 16px;
      padding-left: 10px;
    }
  }
}

h2 {
  font-size: 20px;
}

.address {
  font-size: 14px;
}

.section {
  width: 100%;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;

  .btn {
    width: 120px;
    height: 38px;
    border: 1px solid #999999;
    border-radius: 4px;
    margin-left: 10px;
  }

  .tab {
    width: 180px;
    height: 40px;
    border: 1px solid #999;
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
  }

  .active {
    background: #317f6e;
    border: none;
    line-height: 40px;
  }

  .item {
    width: 270px;
    min-height: 310px;
    border: 1px solid #666666;
    border-radius: 4px;
    margin-top: 15px;
    margin-right: 15px;

    .name {
      font-size: 14px;
      font-weight: 400;
      color: #f3e9ca;
    }

    .img {
      width: 200px;
      height: 255px;
      background: url("../assets/account/bgframezs.png") no-repeat;
      background-size: 100% 100%;
      padding: 8px;
      margin-top: 10px;

      .dragon {
        width: 70%;
        // height: 90%;
        position: absolute;
        bottom: 33px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }

      .persale {
        position: absolute;
        right: -10px;
        top: -10px;
      }

      .jiaobiao {
        width: 74px;
        height: 74px;
        background: url("../assets/public/jiaobiao.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 3px;
        top: 3px;
        text-align: center;
        z-index: 2;

        span {
          display: block;
          position: absolute;
          font-size: 14px;
          top: 32%;
          right: 5px;
          transform: translate(0%, -50%) rotate(45deg);
        }
      }

      .price {
        width: 200px;
        height: 30px;
        background: url("../assets/account/price.png") no-repeat;
        background-size: 100% 100%;
        bottom: 10px;
        line-height: 30px;
        font-size: 14px;
      }

      .block {
        width: 200px;
        height: 30px;
        // background: url("../assets/account/block2.png") no-repeat;
        // background-size: 100% 100%;
        position: absolute;
        bottom: 3px;
        left: 0;
        line-height: 30px;
        font-size: 14px;
        z-index: 2;
      }
    }

    .the_block {
      width: 200px;
      height: 64px;
      background: url("../assets/account/block.png") no-repeat;
      background-size: 100% 100%;
    }

    .modify {
      width: 160px;
      height: 40px;
      border: 1px solid #317F6E;
      border-radius: 4px;
      color: #317F6E;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }

    .oprations {
      min-width: 90px;
      height: 38px;
      background: #317f6e;
      border-radius: 4px;
      line-height: 38px;
    }

    .game_btn {
      min-width: 90px;
      height: 39px;
      line-height: 37px;
      font-size: 13px;
      border: 1px solid #37b497 !important;
      background: transparent !important;
      color: #37b497 !important;
      border-radius: 4px;
    }

    .into_btn {
      min-width: 100%;
    }

    .pick_btn {
      width: 100%;
      height: 38px;
      line-height: 38px;
      background: #f49314;
      border-radius: 4px;
      font-size: 13px;
    }

    .hasactive2 {
      border: 1px solid #37b497;
      background: none;
    }

    .default {
      border: 1px solid #999999;
      background: transparent;
    }

    .activing {
      background: #999;
      cursor: wait;
    }

    .hasactive {
      border: 1px solid #999999 !important;
      background: transparent !important;
    }

    .brp_btn {
      margin-left: 0;
      margin-top: 15px !important;
    }
  }

  .item2 {
    height: auto;

    .imgbg {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .cardimg {
      position: relative;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .borders {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
    }

    .icon_box {
      width: 74px;
      height: 74px;
      position: absolute;
      left: 3px;
      top: 3px;
      z-index: 4;
      background: url("../assets/card/card_jiao.png") no-repeat;
      background-size: 100% 100%;

      p {
        transform: rotate(-45deg);
        margin-top: 13%;
      }
    }

    .icon_box2 {
      background: url("../assets/card/card_jiao2.png") no-repeat;
      background-size: 100% 100%;
    }

    .icon_box3 {
      background: url("../assets/card/card_jiao3.png") no-repeat;
      background-size: 100% 100%;
    }

    .forsale {
      width: 160px;
      height: 90px;
      background: url("../assets/card/card_sell.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
    }

    .price {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background: url("../assets/account/price.png") no-repeat;
      background-size: 100% 100%;
      bottom: 50px;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 4;
    }

    .grade_img {
      position: absolute;
      z-index: 4;
      top: 25px;
      right: 25px;
    }

    .camp_img {
      position: absolute;
      z-index: 4;
      bottom: 0px;
      left: 0px;
    }

    .pros_img {
      position: absolute;
      z-index: 4;
      bottom: 0px;
      right: 0px;
    }

    .timeout {
      width: 130px;
      height: 70px;
      background: url("../assets/account/jishi-bj.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 4;
      transform: translate(-50%, -50%);
    }
  }

  h2 {
    font-size: 20px;
  }

  .activities {
    font-size: 14px;
    font-weight: 400;
    color: #37b497;
    padding-left: 18px;
    cursor: pointer;
  }

  .timeline-account {
    padding: 20px;

    .box {
      p {
        color: #fff;
        margin-bottom: 5px;
      }

      a {
        color: #999;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-decoration: underline;
      }
    }
  }
}

.coin_section {
  flex: 1;
}

.label {
  color: #fff;
  line-height: 26px;
}

.val {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 26px;
}

.tip {
  font-size: 16px;
  font-weight: 400;
  color: #f4ab0b;
  line-height: 22px;
}

.inpDiv {
  width: 100%;
  height: 36px;
  background: #3D3F4C;
  border-radius: 4px;
  position: relative;
  color: #FFFFFF;
  font-size: 16px;
  // padding-left: 10px;

  .text {
    font-size: 14px;
    font-weight: bold;
    color: #52a8ff;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.itemBox {
  min-height: 300px;
}

.box-status-2 {
  width: 100%;

  >div {
    width: 48% !important;
  }

  .oprations,
  .game_btn {
    width: 48% !important;
  }
}

@media screen and (max-width: 768px) {

  .tab-header-left {
    width: 100%;
  }

  .tab-header-right {
    width: 100%;
    // padding-top: 10px;
  }

  .itemBox {
    justify-content: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 1515px) {

  .tab-header-left {
    // width: 80%;
  }

  .tab-header-right {
    width: 20%;
  }

  .coin_section {
    &:nth-child(2) {
      margin-right: 0;
    }

    &:nth-child(3) {
      margin-left: 0;
    }
  }
}</style>